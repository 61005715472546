import React from 'react'
import Icon from './Icon'

const File = props => (
  <Icon {...props}>
    <path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </Icon>
)

export default File
