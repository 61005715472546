import React from 'react'

const NotFound = () => <article style={style}>Not Found</article>

const style = {
  fontSize: '2rem',
  padding: '2rem',
  textAlign: 'center'
}

export default NotFound
