export default {
  development: {
    apiKey: 'AIzaSyBBdtEnbrt8MpwzgRkEQzSKTNYq80HoxVQ',
    authDomain: 'specification-dev.firebaseapp.com',
    databaseURL: 'https://specification-dev.firebaseio.com',
    projectId: 'specification-dev',
    storageBucket: 'specification-dev.appspot.com',
    messagingSenderId: '156229167852',
    appId: '1:156229167852:web:a20b1db4bd729e44c513c9'
  },
  production: {
    apiKey: 'AIzaSyAr4-fCfADHMFvSntnb3atvymIZZBZY7OA',
    authDomain: 'spec-production.firebaseapp.com',
    databaseURL: 'https://spec-production.firebaseio.com',
    projectId: 'spec-production',
    storageBucket: 'spec-production.appspot.com',
    messagingSenderId: '535893078364',
    appId: '1:535893078364:web:55b47d8f9bc55eae7f5199'
  }
}[process.env.REACT_APP_ENV]
