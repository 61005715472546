import React from 'react'
import Icon from './Icon'

const Flag = props => (
  <Icon {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z" />
  </Icon>
)

export default Flag
